<template>
	<v-alert
		class="cta"
		:icon="icon"
		outlined
		:color="color"
		prominent
	>
		<v-row align="center">
			<v-col cols="12" sm="9" lg="10">
				<span class="cta__copy blockquote">Got a question? Get in touch with Sydney Kids Neuropsychology to make an appointment.</span>
			</v-col>
			<v-col cols="12" sm="3" lg="2">
				<v-btn
					class="cta__btn"
					block
					large
					rounded
					depressed
					:color="color"
					to="/contact"
				>
					Contact Us
				</v-btn>
			</v-col>
		</v-row>
	</v-alert>
</template>

<script>
export default {
	name: 'Cta',
	props: {
		color: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		}
	}
};
</script>

<style scoped lang="scss">
.cta {
	// &__copy {
	// 	color: $grey-darker;
	// 	font-size: $font-size-root;
	// }
	&__btn {
		float: right;
	}
}
</style>
